@import '../styles/variables.scss';

.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &-content {
        padding: 2rem 2.625rem;
    }

    .error-icon {
        color: $gmDarkRed;
        height: 1rem;
        width: 1rem;
    }
}
