//GM Colors
$gmBlue: #9dbfe7;
$gmPrimaryBlue: #0072ce; // Navigation bar, Primary buttons, Hyperlinks, Action icons, blue chip, toast messages border
$gmDarkBlue: #00439d; // Selected states; Navigation bar hover state
$gmMediumBlue: #0060bc; // Primary button hover state
$gmLightBlue: #b9dcfa; // Blue non actionable chip border
$gmLightestBlue: #e2f1fc; // Blue chip toast messages background color/menu, hover, select/past message background
$gmDisabledBlue: #9dbfe7; // Disabled primary button

// Neutrals
$gmBlack: rgba(0, 0, 0, 0.87); // Default text color, Footer background
$gmDarkGray: #666; // Default icon color, Sub-headers
$gmGray: #b9b9b9; // Placeholder text, rules and dividers
$gmLightGray: #ddd; // Selected state, tabs
$gmLighterGray: #efefef; // Gray
$gmLightestGray: #f7f7f7; // Backgrounds, data table row banding
$gmWhite: #fff;

// Supporting
$gmDarkRed: #c8000f; // Red chip and Marketing toast messages border color, Destructive button mousedown
$gmRedSecond: #d6001e; // Inline validation text color for error messaging and alert icons
$gmRed: #e30526; // Destructive buttons default
$gmLightRed: #f99698; // Status indicator: alert
$gmGreen: #4a9e38; // Status indicator: approved, accepted
$gmDarkGreen: #07671c; // Icon or non-actionable chip background color. Manufacturing toast messages border color
$gmMediumGreen: #39b54a; // Status indicator: approved, accepted
$gmLightGreen: #d3edc8; // Green chip and Marketing toast messages background color
$gmYellow: #f0b323; // Manufacturing toast messages background color
$gmLightYellow: #f9ebb6; // Yellow chip and Marketing toast messages background color
$gmOrange: #f27d07; // Status indicator: caution, under review, hold
$gmPurple: #8e46e3; // Visited hyperlinks
$gmPink: #ffcbd2; // Red chip and Marketing toast messages background color
